import { useCallback, useState } from 'react';

import * as ROUTE from '../constants/routes'
import useDidMount from './useDidMount';

const useGoal = () => {
  const [goalBalance, setGoalBalance] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const isDidMount = useDidMount();

  const fetchGoalBalance = useCallback(async () => {
    try {
      setLoading(true);
      const authToken = JSON.parse(localStorage.getItem(process.env.REACT_APP_ADMIN_AUTH_TOKEN));
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${authToken.token}`
        }
      }
      if (goalBalance.length === 0 && isDidMount) {
        const _goalBalance =  await fetch(`${ROUTE.ADMIN_API}/goals/balance`, 
          requestOptions)
        .then(res => res.json())
        .then(data =>{
          return data
        })
        .catch(err => console.log(err))
        if (!_goalBalance?.data) {
          if (isDidMount) {
            setLoading(false);
          }
        } else {
          const data = _goalBalance;
          if (isDidMount) {
            setLoading(false);
            setGoalBalance(data.data);
          }
        }
      }
    } catch (e) {
      if (isDidMount) {
        setError('Failed to fetch goal balance');
        setLoading(false);
      }
    }
  }, [isDidMount, goalBalance]);

  return {
    fetchGoalBalance, goalBalance, isLoading, error
  };
};

export default useGoal;
